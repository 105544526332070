<template>
  <div class="ant-hx-table">
    <!--搜索条件 -->
    <div class="ant-hx-table-search">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="" style="width: 60%">
          <div class="setStartAddress">
            <a-cascader style="width:47%" v-model:value="searchForm.startAraay" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setStartAddress" :show-search="{ filter }" placeholder="选择起始地" />
            <a-tooltip>
              <template #title>两极反转</template>
              <div style="display:flex;align-items:center">
                <RetweetOutlined style="font-size:20px" @click="reversal" />
              </div>
            </a-tooltip>
            <a-cascader style="width:47%" v-model:value="searchForm.endAraay" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="setEndAddress" :show-search="{ filter }" placeholder="选择目的地" />
          </div>
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
            <a-button @click="exportAll">
              导出全部
            </a-button>
            <a-button @click="batchShow=true , batchForm={}">
              批量设置
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-card :bodyStyle="{'padding':'0px'}" ref="card">
      <div class="paginationCss">
        <a-pagination v-model:current="pagination.current" :total="pagination.total" show-size-changer show-quick-jumper :disabled="loading" :page-size="pagination.pageSize" :show-total="pagination.showTotal" @showSizeChange="handleTableChange" @change="handleTableChange">
          <template #buildOptionText="props">
            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            <span v-else>全部</span>
          </template>
        </a-pagination>
      </div>
      <div v-if="listData.length>0&&loading===false">
        <div class="a-card-table" v-for="(item,index) in listData" :key="index">
          <div class="a-card-table-row">
            <a-row>
              <a-col :span="4">线路名称：<span class="col-head">{{ item.startEndCity }}</span> </a-col>
              <a-col :span="4">线路方案：<span class="col-head">{{ item.lineName }} </span> </a-col>
              <a-col :span="16" class="col-set">承运方：{{ item.carrierName }}</a-col>
              <a-col :span="4" class="col-set">成本：￥{{ item.carrierFinalPrice }}</a-col>
              <a-col :span="4" class="col-set">报价：￥{{ item.finalPrice }}</a-col>
              <a-col :span="3" class="col-set">时效：{{ item.prescription }}</a-col>
              <a-col :span="3" class="col-set">途径地：{{ item.passPoint }}</a-col>
              <a-col :span="3" class="col-set">承运次数：{{ item.num }}</a-col>
              <a-col :span="5" class="col-set ">备注：
                <span class="col-remark">{{ item.remark }}</span>
              </a-col>
            </a-row>
            <div v-if="item.noLineList!=null">
              <h1 class="nullDataH1">当前无可发运线路，系统建议路线</h1>
              <div class="noPlanList" :class="itemDetailed.state===1?'planList':''" v-for="(itemDetailed , index) in item.noLineList" :key="index">
                <a-row >
                  <a-col :span="24">
                    步骤{{index+1}}
                  </a-col>
                  <a-col :span="24">
                    线路名: {{itemDetailed.lineName}}
                  </a-col>
                  <a-col :span="24">
                    承运商:   {{itemDetailed.carrierName}}
                  </a-col>
                  <a-col :span="6">
                    成本：  {{itemDetailed.price}}
                  </a-col>
                  <a-col :span="6">
                    时效： {{itemDetailed.prescription}}
                  </a-col>
                  <a-col :span="6">
                    公里： {{itemDetailed.km}}
                  </a-col>
                  <a-col :span="24">
                    <div style="width: 577px; word-break: break-all;">
                      备注：  <span :class="itemDetailed.state===0?'noRemark':''">{{itemDetailed.remark}}</span>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <div class="a-card-table-form" v-if="item.noLineList==null">
            <a-row>
              <a-col :span="2"> C端成本报价设置</a-col>
              <a-col :span="12" class="d">
                价格上浮：公司利润：
                <a-input :disabled="item.disabled" class="set-show-input" size="small" type="number" @change="onFloatPriceChange(item)" v-model:value="item.floatPrice" />
                元，
                推广成本：
                <a-input :disabled="item.disabled" class="set-show-input" size="small" type="number" v-model:value="item.promoCost" />
                元，
                活动成本：
                <a-input :disabled="item.disabled" class="set-show-input" size="small" type="number" v-model:value="item.activityCost" />
                元。
              </a-col>
              <a-col :span="5" class="d">
                <div>提示语：</div>
                {{item.hint}}
              </a-col>
              <a-col :span="3">状态：
                {{item.state===true?'打开':'关闭'}}
              </a-col>
              <a-col class="show" :span="1">
                <a-button v-if="item.disabled" class="editButton" size="small" @click="item.disabled=!item.disabled">
                  修改
                </a-button>
                <a-button v-if="!item.disabled" type="primary" size="small" :loading="disabledLoading" @click="onSubmit(item)">
                  确认提交
                </a-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div v-else class="a-card-table">
        <div class="none" v-if="loading">
          <a-button :loading="loading">数据加载中</a-button>
        </div>
        <div class="none" v-else>暂无数据</div>
      </div>

    </a-card>

    <a-modal v-model:visible="batchShow" title="批量设置" :confirm-loading="batchLoading" width="600px" @ok="onBatchEdit" okText="确认提交">
      <a-form :model="batchForm" ref="batchFormFormRef" layout="vertical" :rules="rules">
        <a-form-item label="起始地">
          <div class="setStartAddress">
            <a-cascader style="width:47%" v-model:value="batchForm.startAraay" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="batchStartAddress" :show-search="{ filter }" placeholder="选择起始地" />
            <div class="">—</div>
            <a-cascader style="width:47%" v-model:value="batchForm.endAraay" :options="$store.state.app.cityDict" :changeOnSelect="true" @change="batchEndAddress" :show-search="{ filter }" placeholder="选择目的地" />
          </div>
        </a-form-item>
        <a-form-item label="城市等级">
          <div class="setStartAddress">
            <a-select style="width:220px" class="set-show-input" v-model:value="batchForm.startCityLevel" :options="cityLevelType" placeholder="" />
            <div class="">—</div>
            <a-select style="width:220px" class="set-show-input" v-model:value="batchForm.endCityLevel" :options="cityLevelType" placeholder="" />
            <a-button value="small" type="primary" @click="onSaveLevel">
              保存
            </a-button>
          </div>
          <div class="startEndLevels">
            <template v-for="(tag, index) in batchForm.startEndLevels" :key="tag">
              <a-tag closable @close="handleClose(tag)">
                {{ tag.startCityLevelName }}-{{ tag.endCityLevelName }}
              </a-tag>
            </template>
          </div>
        </a-form-item>
        <a-form-item label="公司利润" required name="floatPrice">
          <a-input v-model:value="batchForm.floatPrice" type="number" suffix="元" />
        </a-form-item>
        <a-form-item label="推广成本" required name="promoCost">
          <a-input v-model:value="batchForm.promoCost" type="number" suffix="元" />
        </a-form-item>
        <a-form-item label="活动成本" required name="activityCost">
          <a-input v-model:value="batchForm.activityCost" type="number" suffix="元" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { RetweetOutlined } from '@ant-design/icons-vue';
import { page, editLineManage, batchCEndLineManage } from '@/api/carrier/optimalLine'
import { message } from 'ant-design-vue'
export default {
  components: {
    RetweetOutlined
  },
  setup () {
    const batchFormFormRef = ref()
    const state = reactive({
      rules: {
        level: { required: true, message: '请填写城市等级' },
        startAraay: { required: true, message: '请填写起始地' },
        floatPrice: { required: true, message: '请填写公司利润' },
        promoCost: { required: true, message: '请填写推广成本' },
        activityCost: { required: true, message: '请选择活动成本' }
      },
      batchLoading: false,
      batchShow: false,
      disabledLoading: false,
      batchForm: {},
      loading: true,
      listData: [],
      cityLevelType: [
        {
          label: '一级城市',
          value: 1
        },
        {
          label: '二级城市',
          value: 2
        },
        {
          label: '三级城市',
          value: 3
        },
        {
          label: '四级城市',
          value: 4
        },
        {
          label: '五级城市',
          value: 5
        }
      ],
      searchForm: {
        startAddress: {},
        endAddress: {}
      },
      pagination: {
        total: null,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        startCityId: state.searchForm.startCityId,
        startProvinceId: state.searchForm.startProvinceId,
        startAreaId: state.searchForm.startAreaId,
        endCityId: state.searchForm.endCityId,
        endProvinceId: state.searchForm.endProvinceId,
        endAreaId: state.searchForm.endAreaId,
        endType: 3,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        totalSize: state.pagination.total
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
          state.listData.forEach(e => {
            e.disabled = true
          })
        }
      }).catch(err => {
        console.log('分页错误   ' + err)
      })
        .finally(() => {
          state.loading = false
        })
    }
    const handleTableChange = (pag, filters) => {
      state.pagination.current = pag
      state.pagination.pageSize = filters
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {
        startAddress: {},
        endAddress: {}
      }
      state.pagination.total = null
      loadData()
    }

    onMounted(() => {
      loadData()
    })
    const reversal = () => {
      if (state.searchForm.startProvinceId && state.searchForm.endProvinceId) {
        const start = { // 反转赋值
          startAddress: state.searchForm.endAddress,
          startAraay: state.searchForm.endAraay,
          startAreaId: state.searchForm.endAreaId,
          startCityId: state.searchForm.endCityId,
          startProvinceId: state.searchForm.endProvinceId,
        }
        const end = { // 反转赋值
          endAddress: state.searchForm.startAddress,
          endAraay: state.searchForm.startAraay,
          endAreaId: state.searchForm.startAreaId,
          endCityId: state.searchForm.startCityId,
          endProvinceId: state.searchForm.startProvinceId,
        }
        state.searchForm = { ...start, ...end }
      } else message.error('请先选择线路')
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvinceId = value[0]
      if (value.length > 1) {
        state.searchForm.startCityId = value[1]
      } else {
        state.searchForm.startCityId = ''
      }
      if (value.length > 2) {
        state.searchForm.startAreaId = value[2]
      } else {
        state.searchForm.startAreaId = null
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endProvinceId = value[0]
      if (value.length > 1) {
        state.searchForm.endCityId = value[1]
      } else {
        state.searchForm.endCityId = ''
      }
      if (value.length > 2) {
        state.searchForm.endAreaId = value[2]
      } else {
        state.searchForm.endAreaId = null
      }
    }
    // 模糊搜索
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    // 导出全部
    const exportAll = () => {
      message.error("研发中")
    }
    // 确认提交
    const onSubmit = (e) => {
      state.disabledLoading = true
      if (e.state) {
        e.state = 1
      } else {
        e.state = 0
      }
      e.floatType = 1
      editLineManage({ ...e, endType: 3 }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      }).catch(err => {
      }).finally(() => {
        state.disabledLoading = false
      })
      if (e.state === 1) {
        e.state = true
      } else {
        e.state = false
      }
    }
    // 批量修改
    const onBatchEdit = () => {
      batchFormFormRef.value.validate()
        .then(() => {
          if (((state.batchForm.startAraay === [] || state.batchForm.startAraay === undefined) && (state.batchForm.endAraay === [] || state.batchForm.endAraay === undefined)) && (state.batchForm.startEndLevels === [] || state.batchForm.startEndLevels === undefined)) {
            message.error('城市等级与起始地必须选择一个上传')
            return
          }
          if (state.batchForm.state) {
            state.batchForm.state = 1
          } else {
            state.batchForm.state = 0
          }
          state.batchLoading = true
          batchCEndLineManage(state.batchForm).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.batchShow = false
              state.batchForm = {}
              loadData()
            }
          }).catch(err => { console.log(err) })
            .finally(() => {
              state.batchLoading = false
            })
        })
    }

    const onFloatPriceChange = (e) => {
      if (Number(e.floatPrice) < 0) {
        e.floatPrice = ''
      }
    }
    const batchStartAddress = (value, selectedOptions) => {
      state.batchForm.startProvinceId = value[0]
      if (value.length > 1) {
        state.batchForm.startCityId = value[1]
      } else {
        state.batchForm.startCityId = null
      }
    }
    const batchEndAddress = (value, selectedOptions) => {
      state.batchForm.endProvinceId = value[0]
      if (value.length > 1) {
        state.batchForm.endCityId = value[1]
      } else {
        state.batchForm.endCityId = null
      }
    }
    // 保存城市等级
    const onSaveLevel = () => {
      if (state.batchForm.startCityLevel === null || state.batchForm.startCityLevel === undefined) {
        message.error('必须选择起始城市等级')
        return
      }
      if (state.batchForm.endCityLevel === null || state.batchForm.endCityLevel === undefined) {
        message.error('必须选择目的城市等级')
        return
      }
      if (state.batchForm.startEndLevels === null || state.batchForm.startEndLevels === undefined) {
        state.batchForm.startEndLevels = []
      }
      let startEndLevelsSum = 0
      state.batchForm.startEndLevels.forEach(e => {
        if (e.name === (state.batchForm.startCityLevel + '-' + state.batchForm.endCityLevel)) {
          startEndLevelsSum++
        }
      })
      if (startEndLevelsSum > 0) {
        message.error('你选的城市等级已有重复的')
        return
      }
      state.batchForm.startEndLevels.push({
        name: state.batchForm.startCityLevel + '-' + state.batchForm.endCityLevel,
        startCityLevel: state.batchForm.startCityLevel,
        endCityLevel: state.batchForm.endCityLevel,
        startCityLevelName: state.cityLevelType[state.batchForm.startCityLevel - 1].label,
        endCityLevelName: state.cityLevelType[state.batchForm.endCityLevel - 1].label
      })
      state.batchForm.startCityLevel = null
      state.batchForm.endCityLevel = null
    }
    // 删除城市等级
    const handleClose = removedTag => {
      const tags = state.batchForm.startEndLevels.filter(tag => tag !== removedTag)
      console.log(tags)
      state.batchForm.startEndLevels = tags
    }
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      reversal,
      handleTableChange,
      setStartAddress,
      setEndAddress,
      filter,
      exportAll,
      onSubmit,
      batchFormFormRef,
      onBatchEdit,
      onFloatPriceChange,
      batchEndAddress,
      batchStartAddress,
      handleClose,
      onSaveLevel
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../TableTool.less';
</style>
